var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"complate-swiper-warp"},[(_vm.swiperItem.type === 'banner')?_c('div',{staticClass:"banner"},[(_vm.swiperItem.options.length> 0)?_c('swiper',{ref:"mySwiper",attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.swiperItem.options),function(item,index){return _c('swiper-slide',{key:index,attrs:{"width":"100%"}},[_c('img',{attrs:{"width":"100%","src":item.imgUrl,"alt":""}}),_c('div',{staticClass:"mark-box"},[_c('div',{staticClass:"home1 center-box"},[_c('div',{staticClass:"logo main-rubberBand",style:({width: index === 0 ? 'auto':'200px', ...item.slot.cssData.logoCss})},[_c('img',{attrs:{"src":item.slot.logoUrl,"alt":"","srcset":""}})]),_c('div',{staticClass:"title main-rubberBand"},[_vm._v(_vm._s(_vm.$store.state.lang=='zh'?item.slot.titleLangZh: item.slot.title))]),_c('div',{staticClass:"desc main-rubberBand",style:(!item.slot.titleLangZh&&!item.slot.title?'marginTop: 72px': '')},[_vm._v(" "+_vm._s(_vm.$store.state.lang=='zh'?item.slot.descLangZh: item.slot.desc)+" ")])])])])}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})],2):_vm._e()],1):(_vm.swiperItem.type !== 'portfolio')?_c('div',{staticClass:"main-bg-box"},[_c('img',{attrs:{"src":_vm.swiperItem.imgUrl,"alt":""}}),_c('div',{staticClass:"mark-box"},[_c('div',{class:[
            'main-box',
            
            'center-box',
            _vm.swiperItem.boxDirection === 'left'? 'box-left ' : 'box-right',
            _vm.swiperItem.textType === 'black'? 'black' : 'light',
          ]},[_c('div',{staticClass:"title main-fadeIn wow",attrs:{"data-wow-offset":"-300","data-wow-duration":"2s"}},[_vm._v(_vm._s(_vm.$store.state.lang=='zh'?_vm.swiperItem.titleLangZh: _vm.swiperItem.title))]),_c('div',{staticClass:"desc main-fadeInLeft wow 'indexFontSize',",attrs:{"data-wow-offset":"-200"}},[_vm._v(_vm._s(_vm.$store.state.lang=='zh'?_vm.swiperItem.descLangZh: _vm.swiperItem.desc))]),(_vm.swiperItem.btnName)?_c('div',{ref:"aboutusBtn",staticClass:"home-btn-box main-fadeIn wow desc",attrs:{"data-wow-offset":"-300"},on:{"click":function($event){return _vm.toUrlHandle(_vm.swiperItem.btnName)}}},[_c('span',[_vm._v(_vm._s(_vm.$store.state.lang=='zh'? _vm.swiperItem.btnNameLangZh:_vm.swiperItem.btnName))])]):_vm._e()])])]):_vm._e(),(_vm.swiperItem.type === 'portfolio' && _vm.lists.length > 0)?_c('div',{staticClass:"banner portfolio"},[_c('img',{attrs:{"width":"100%","src":require('@/assets/images/portfolic-bg.jpg'),"alt":""}}),_c('div',{staticClass:"portfolio-swiper-box center-box"},[(_vm.$store.state.lang=='en')?_c('div',{staticClass:"portfolic-title",attrs:{"data-wow-duration":"2s"}},[_vm._v("OUR PORTFOLIO")]):_vm._e(),(_vm.$store.state.lang=='zh')?_c('div',{staticClass:"portfolic-title",attrs:{"data-wow-duration":"2s"}},[_vm._v("投资项目")]):_vm._e(),_c('swiper',{staticStyle:{"width":"100%"},attrs:{"arrow":"always","options":_vm.portfolioSwiperOption}},_vm._l((Math.ceil(_vm.lists.length / 10)),function(item,index){return _c('swiper-slide',{key:index,staticStyle:{"width":"100%"}},[_c('div',{staticClass:"logos-box"},_vm._l((_vm.lists.slice(
                    index * 10,
                    10 * item
                  )),function(citem,cindex){return _c('div',{key:'c' + cindex,staticClass:"imgbox"},[_c('img',{class:['hover-rubberBand','hover-jello', 'hover-shakeX','hover-pulse']
                    [Math.floor((Math.random()*['hover-rubberBand','hover-jello','hover-shakeX','hover-pulse'].length))],attrs:{"src":citem.imgUrlIndex,"alt":""}})])}),0)])}),1),_c('div',{staticClass:"swiper-button-prev",attrs:{"slot":"button-prev"},slot:"button-prev"}),_c('div',{staticClass:"swiper-button-next",attrs:{"slot":"button-next"},slot:"button-next"}),_c('div',{staticClass:"portfolic-more",on:{"click":function($event){return _vm.toUrlHandle('MORE PORTFOLIO')}}},[_c('span',[_vm._v("MORE PORTFOLIO")])])],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }