<template>
  <div class="home">
    <!-- <div v-for="(item, index) in swiperLists" :key="index">
      
    </div> -->
    <div style="white-space: normal;">
      <swiper :options="swiperOption2" class="mySwiper2" ref="mySwiper2">
          <swiper-slide width="100%" v-for="(item, index) in swiperLists" :key="index">
            <ComplateSwiper :swiperItem='item' />
          </swiper-slide>
      </swiper>
    </div>
    
    
  </div>
</template>

<script>
// @ is an alias to /src
import ComplateSwiper from '@/components/ComplateSwiper.vue';
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";


export default {
  name: 'Home',
  components: {
    swiper,
    swiperSlide,
    ComplateSwiper
  },
  computed:{
    swiperLists(){
      const home = this.$store.state.mainContent.home
      const lang = this.$store.state.lang
      let obj = {}
      let bannerObj = {
        type: 'banner',
        options: []
      }
      let arr = []
      if(home && home.extend){
        obj = {
          ...home,
          ...JSON.parse(home.extend)
        }
        // console.log(obj.banners[9].cssData);
        // console.log(JSON.stringify({"logoCss":"{width: 50px}"}));
        // const a= JSON.parse(obj.banners[9].cssData);
        
        // console.log(JSON.parse(a.logoCss.replace(/'/g,'"')));
        obj.banners.map( (item) => {
          let cssData = {}
          if(item.cssData&&JSON.parse(item.cssData).constructor === Object){
            cssData = JSON.parse(item.cssData)
            Object.keys(cssData).map( (item) => {
              cssData[item] = JSON.parse(cssData[item].replace(/'/g,'"'))
            })
          }
          
          bannerObj.options.push({
            imgUrl: item.bgUrl,
            slot: {
              logoUrl: item.logo,
              title: item.title,
              cssData: cssData,
              titleLangZh: item.titleLangZh,
              desc: item.introduce,
              descLangZh: item.introduceLangZh,
            },  
          })
        })
        arr.push( bannerObj )
        obj.mainContent.map( (item) => {
          arr.push({
            imgUrl: item.bgUrl,
            titleLangZh: item.titleLangZh,
            title: item.title,
            btnNameLangZh: item.btnNameLangZh,
            btnName: item.btnName,
            desc: item.introduce,
            descLangZh: item.introduceLangZh,
            textType: item.isBlack?'black':'light',
            boxDirection: item.isRight?'right':'left',
          })
        })

      }
      
      arr.push({
          type: 'portfolio',
          title: 'OUR PORTFOLIO',
          imgUrl: require('@/assets/images/portfolic-bg.jpg'),
          btnName: 'MORE PORTFOLIO',
          // options: [
          //   {
          //     imgUrl: require('@/assets/images/por1.png'),
          //   },
          //   {
          //     imgUrl: require('@/assets/allImage/tucheng6.png'),
          //   },
          //   {
          //     imgUrl: require('@/assets/allImage/tucheng7.png'),
          //   },
          //   {
          //     imgUrl: require('@/assets/allImage/tucheng8.png'),
          //   },
          //   {
          //     imgUrl: require('@/assets/allImage/tucheng8.png'),
          //   },
          //   {
          //     imgUrl: require('@/assets/allImage/tucheng9.png'),
          //   },
          //   {
          //     imgUrl: require('@/assets/allImage/tucheng10.png'),
          //   },
          //   {
          //     imgUrl: require('@/assets/allImage/tucheng11.png'),
          //   },
          //   {
          //     imgUrl: require('@/assets/allImage/tucheng12.png'),
          //   },
          //   {
          //     imgUrl: require('@/assets/allImage/tucheng8.png'),
          //   },
          //   {
          //     imgUrl: require('@/assets/allImage/tucheng8.png'),
          //   },
          // ],
        },)
      
      return arr 
    }
  },
  data() {
    return {
      swiperOption2:{
        direction: 'vertical',
        // autoHeight: true,
        slidesPerView: 'auto',
      }
      // swiperLists: [
      //   {
      //     type: 'banner',
      //     options: [
      //       {
      //         imgUrl: require('@/assets/images/home-banner-1.jpg'),
      //         slot: {
      //           logoUrl: require('@/assets/images/home-logo.png'),
      //           title: 'Investing in the Consumer Growth Sector',
      //           desc: 'ClearVue Partners is a private equity firm focused on the consumer sector of China, investing in fast growing companies that are informed by data, and powered by technology.',
      //         },
      //       },
      //       {
      //         imgUrl: require('@/assets/images/home-banner-2.jpg'),
      //         slot: {
      //           logoUrl: require('@/assets/images/pony.png'),
      //           title: 'Leading Autonomous Driving Comnpany',
      //           desc: 'At Pony.ai, we aim to deliver autonomous mobility everywhere by building safe and reliable self-driving technology.',
      //         },
      //       },
      //     ],

      //   },
      //   {
      //     imgUrl: require('@/assets/images/home2.jpg'),
      //     title: 'Building Consumer Ecosystems,Informed by Data, Powered by Technology',
      //     desc: 'ClearVue Partners is a private equity firm focused on the consumer sector of China, investing in fast growing companies that are informed by data, and powered by technology. We invest along the consumer megatrend themes of modernization & new retail, lifestyle, health & wellness, and the digital consumer. We target growth stage investments in fast growing and promising local companies, enable market entry for leading international consumer brands into China, and develop top tier brands and businesses.',
      //     btnName: 'ABOUT US',
      //     textType: 'light',
      //     boxDirection: 'left',
      //   },
      //   {
      //     imgUrl: require('@/assets/images/home3.jpg'),
      //     title: 'Monofilament is not a line makes no garland',
      //     desc: ' Our team has a unique combination of investment and senior operations experience from the US and Greater China with an advisory group of recognized business pioneers in the consumer industry. We believe in operational value add for our portfolio investments, and bring global and multi-national best practices and relationships to rapidly growing companies in China.',
      //     btnName: 'OUR TEAM',
      //     textType: 'black',
      //     boxDirection: 'right',
      //   },
        
      // ],
    };
  },
  methods:{

  },
  created(){
    // document.title = 'ClearVue'
  },
  mounted(){
  }
};
</script>

<style lang="scss" scoped>
  .home{
    width: 100%;
    // max-width: 1920px;
    margin: 0 auto;
    // height: 100%;
  }
</style>
