import { get, post } from "@/utils/request";

export const getSometings = () => {
  return post('common-content/page');
};

// { id: 1, name: "HOME" },
// { id: 2, name: "ABOUT" },
// { id: 3, name: "TEAM" },
// { id: 4, name: "PORTFOLIO" },
// { id: 5, name: "NEWS" },
// { id: 6, name: "CONTCAT" },

// 获取位置及备案号
export const getLocation = () => {
  return post('common-content/page', {typeid: 6});
}

// 获取所有数据
export const getMainContent = () => {
  return post('common-content/page');
}

// 获取团队列表
export const getTeamsContent = () => {
  return post('teams/page', {pageNo: 1, pageSize: 1024});
}

// 获取项目列表
export const getPortfolioContent = () => {
  return post('portfolio/page', {pageNo: 1, pageSize: 1024});
}
// 获取新闻列表
export const getNewsContent = (data) => {
  return post('news/page', data);
}

// 获取详细信息
export const getMenberInfo = (id) => {
  return post('teams/page', {id});
}