<template>
  <div
    :class="{
      'menu-warp': true,
      'main-backInDown': flag,
      'main-backOutUp': !flag,
    }"
    :style="{ backgroundColor: bgColor }"
    ref="warp"
  >
    <ul class="menu-list">
      <router-link v-for="item in navs" :key="item.url" :to="item.url">
        {{$t(`nav.${item.name}`)}}
      </router-link>
      <div class="lang_box">
        <!-- <img :src="langImg" alt=""> -->
        <span @click="onClick('en')" :class="{active: $store.state.lang=='zh'}">EN</span> 
        <span @click="onClick('zh')" :class="{active: $store.state.lang=='en'}">中文</span>
      </div>
    </ul>
    
  </div>
</template>

<script>
import Navs from "@/constants/navs";

export default {
  name: "TopNav",
  data() {
    return {
      navs: Navs,
      bgColor: {},
      flag: true,
      langImg: require('./lang.png')
    };
  },
  watch: {
    $route(to) {
      if (to.path === "/home" || to.path === "/") {
        this.bgColor = "transparent";
      } else {
        this.bgColor = "#000";
      }
    },
  },
  methods: {
    onClick(lang) {
      console.log(i18n.locale);
      localStorage.setItem('localeLanguage', lang)
      i18n.locale = lang;
      this.$store.commit('setLang',lang)
    },
    handleScroll(e) {
      const scrollTop =
        e.target.documentElement.scrollTop || e.target.body.scrollTop;
      const isShow = scrollTop > 500;
      this.flag = !isShow;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  destroyed() {
    document.removeEventListener("scroll", this.handleScroll);
  },
};
</script>

<style scoped lang="scss">
.menu-warp {
  position: fixed;
  min-width: 1100px;
  width: 100%;
  z-index: 9;
  left: 0;
  right: 0;
  
  .menu-list {
    height: 106px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 860px;
    width: 60%;
    margin: 0 auto;
    // position: relative;
  }

  a {
    color: #fff;
    text-decoration: none;
    display: inline-block;
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    transition-duration: 0.2s;
    font-size: 18px;
    transition-property: box-shadow, transform;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      transform: scaleX(0);
      height: 1px;
      bottom: 1px;
      left: 0;
      background-color: #fff;
      transform-origin: bottom right;
      transition: transform 0.2s ease-out;
    }

    &:hover {
      box-shadow: 1px 10px 10px -10px rgba(0, 0, 0, 0.5);
      transform: scale(1.2);
    }

    &:hover::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }

    &.router-link-exact-active {
      color: #fff;
    }

    &.router-link-exact-active::after {
      transform: scaleX(1);
    }
  }
  .lang_box{
    position: absolute;
    right: 120px;
    top: 50%;
    width: 30px;
    height: 30px;
    font-size: 12px;
    color: #999;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #fff;
    font-weight: bold;
    border-radius: 50%;
    img{
      width: 20px;
      // vertical-align: middle;
      margin-right: 10px;
    }
    span{
      display: inline-block;
      cursor: pointer;
      display: none;
      vertical-align: middle;
    }
    .active{
      color: #fff;
      
      display: inline-block;
    }
  }
}
</style>
