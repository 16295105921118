import Vue from 'vue';
import i18n from './locales';
import App from './App.vue';
import router from './router';
import store from './store';
import BaiduMap from 'vue-baidu-map'

Vue.config.productionTip = false;

Vue.use(BaiduMap, {
  /* 需要注册百度地图开发者来获取你的ak */
  ak: 'QTOVV0lpheEtzanOi4G9Gww9PEbZAQw8'
})

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
