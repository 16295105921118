import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    mainContent: {},
    lang: window.localStorage.getItem('localeLanguage'),
    
    fullWidth:0,
  },
  mutations: {
    setMainContent(state, mainContent){
      state.mainContent = mainContent;//将传参设置给state的city
    },
    setLang(state, lang){
      state.lang = lang;//将传参设置给state的city
    },
    setFullWidth(state, width){
      state.fullWidth = width
    }
  },
  actions: {
  },
  modules: {
  },
});
