<template>
  <div id="app">
    <div class="test-bnt">
      <!-- <button v-on:click="onClick('zh')">中文</button>
      <button v-on:click="onClick('en')">英文</button> -->
      <!-- <button v-on:click="request">request</button> -->
    </div>
    <TopNavMenu />
    <router-view />
    <CvpFooter />
  </div>
</template>

<script>
import TopNavMenu from "@/components/TopNav.vue";
import CvpFooter from "@/components/CvpFooter.vue";
import { getSometings, getMainContent } from "@/apis";

export default {
  components: { TopNavMenu, CvpFooter },
  
  updated() {
    window.scroll(0, 0);
  },
  watch: {
    fullWidth(val) {
      this.$store.commit('setFullWidth',val)
      if(val < 1006 &&  document.documentElement.clientWidth < document.documentElement.clientHeight){
        window.location.href='https://m.cvpcap.com/'
        // window.location.href='http://114.55.26.1/m-cvpcap/'
        // window.location.href='http://192.168.0.19:12316/#/'
      }
      // else if(document.body.clientWidth < document.body.clientHeight){
      //   // window.location.href='https://m.cvpcap.com/'
      //   window.location.href='http://114.55.26.1/m-cvpcap/'
      //   // window.location.href='http://192.168.0.19:12316/#/'
      // }  
    }
  },
  data() {
    return {
      fullWidth: 0
    }
  },
  methods: {
    onClick(lang) {
      console.log(i18n.locale);
      i18n.locale = lang;
      this.$store.commit('setLang',lang)
    },
    request() {
      getSometings()
        .then((result) => {
          console.log(result);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getMainContent(){
      const res = await getMainContent()
      const obj = {}
      res.data.map((item) =>{
        switch (item.typeid) {
          case 1:
            obj.home = item
            break;
          case 2:
            obj.about = item
            break;
          case 3:
            obj.team = item
            break;
          case 4:
            obj.portfolio = item
            break;
          case 5:
            obj.news = item
            break;
          case 6:
            obj.contact = item
            break;
        
          default:
            break;
        }
      })
      this.$store.commit('setMainContent',obj)
    }
  },
  created(){
    window.fullWidth = document.documentElement.clientWidth
    this.fullWidth = window.fullWidth

    
    this.getMainContent()
  },
  mounted(){
    document.title = "ClearVue Partners"
    const that = this
      window.onresize = () => {
        return (() => {
          window.fullWidth = document.documentElement.clientWidth
          that.fullWidth = window.fullWidth
        })()
      }
  }
};
</script>

<style lang="scss">
@import url("./assets/common.scss");

html,
body {
  
  min-height: 100%;
  width: 100%;
  height: auto;
}

#app {
  width: 100%;
  min-width: 1000px;
  height: 100%;
  font-family: Arial;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  white-space:pre-wrap;
}

.test-bnt {
  position: absolute;
  background: red;
  z-index: 9999;
}
</style>
