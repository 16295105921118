<template>
  <div class="cvpFooter-warp main-width">
    <div class="footer-box">
      <div class="logo">
        <img width="200px" src="../assets/images/footer-logo.png" alt="" />
      </div>
      <div class="center-box copycom">© 2022 CLEARVUE</div>
      <div class="other h-center-box">
        <!-- <div class="office">{{$store.state.lang=='zh'?footerData.officeLangZh:footerData.office}}</div>
        <div>{{$store.state.lang=='zh'?footerData.addressLangZh:footerData.address}}</div> -->
        <!-- <div>{{footerData.phone}}</div>
        <div>{{footerData.fax}}</div>
        <div>{{footerData.email}}</div> -->
        <div @click="toUrlHandle">{{footerData.recordNumber}}</div>
      </div>
    </div>
  </div>
</template>

<script>

import { getLocation } from "@/apis";
export default {
  name: "CvpFooter",
  data() {
    return {
      msg: "Welcome to your vueName",
      footerData: {}
    };
  },
  created() {},
  methods: {
    toUrlHandle(){
       window.open('https://beian.miit.gov.cn/', '_blank');
    },
    async getLocation(){
      const res = await getLocation();
      console.log(res.data[0]);
      this.footerData = JSON.parse(res.data[0].extend)
    }
  },
  mounted() {
    this.getLocation()
  },
};
</script>

<style scoped lang="scss">
.cvpFooter-warp {
  background-color: #2a324d;
  color: #fff;
  padding: 40px 0;
  margin: 0 auto;
  position: relative;
  .footer-box {
    width: 80%;
    height: 100%;
    margin: 0 auto;
    .copycom{
      position: absolute;
    }
  }
}

.other {
  .office {
    font-size: 32px;
    line-height: 60px;
    font-weight: bold;
  }
  width: 40%;
  position: absolute;
  right: 10%;
  text-align: right;
  line-height: 28px;
  & > div {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
