const Navs = [
  { name: 'home', url: '/' },
  { name: 'about', url: '/about' },
  { name: 'team', url: '/team' },
  { name: 'portfolio', url: '/portfolio' },
  { name: 'news', url: '/news' },
  { name: 'contact', url: '/contact' },
];

export default Navs;
