<template>
  <div class="complate-swiper-warp">
    <!-- <img :src="swiperItem.imgUrl" alt="">
        <div class="mark-box">
            <div v-if="swiperItem.slotType === 'home1'" class="home1 center-box">
                <div class="logo">
                    <img :src="swiperItem.slot.logoUrl" alt="" srcset="">
                </div>
                <div class="title">Investing in the Consumer Growth Sector</div>
                <div class="desc"><ScorllText :text='text'/></div>
            </div>
        </div> -->
    <!-- <div class="banner" v-if="swiperItem.type === 'banner'">
            <div v-for="(item, index) in  swiperItem.options" :key="index">
                <img :src="item.imgUrl" alt="">
                <div class="mark-box">
                    <div v-if="item.slotType === 'home1'" class="home1 center-box">
                        <div class="logo">
                            <img :src="item.slot.logoUrl" alt="" srcset="">
                        </div>
                        <div class="title">Investing in the Consumer Growth Sector</div>
                        <div class="desc"><ScorllText :text='text'/></div>
                    </div>
                </div>
            </div>
        </div> -->
    <div class="banner" v-if="swiperItem.type === 'banner'">
      <swiper v-if="swiperItem.options.length> 0" :options="swiperOption" ref="mySwiper">
        <swiper-slide width="100%" v-for="(item, index) in swiperItem.options" :key="index">
          <img class="" width="100%" :src="item.imgUrl" alt="" />
          <div class="mark-box">
            <div class="home1 center-box">
              <div class="logo main-rubberBand" :style="{width: index === 0 ? 'auto':'200px', ...item.slot.cssData.logoCss}">
                <img :src="item.slot.logoUrl" alt="" srcset="" />
              </div>
              <div class="title main-rubberBand">{{$store.state.lang=='zh'?item.slot.titleLangZh: item.slot.title }}</div>
              <div :style="!item.slot.titleLangZh&&!item.slot.title?'marginTop: 72px': ''" class="desc main-rubberBand">
                {{ $store.state.lang=='zh'?item.slot.descLangZh: item.slot.desc }}
              </div>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>

   

    <div class="main-bg-box" v-else-if="swiperItem.type !== 'portfolio'">
      <img :src="swiperItem.imgUrl" alt="" />
      <div class="mark-box">
        <div
          :class="[
            'main-box',
            
            'center-box',
            swiperItem.boxDirection === 'left'? 'box-left ' : 'box-right',
            swiperItem.textType === 'black'? 'black' : 'light',
          ]"
        >
          <div class="title main-fadeIn wow" data-wow-offset="-300" data-wow-duration="2s">{{ $store.state.lang=='zh'?swiperItem.titleLangZh: swiperItem.title }}</div>
          <div class="desc main-fadeInLeft wow 'indexFontSize'," data-wow-offset="-200">{{ $store.state.lang=='zh'?swiperItem.descLangZh: swiperItem.desc }}</div>
          <div v-if="swiperItem.btnName" ref="aboutusBtn" class="home-btn-box main-fadeIn wow desc" data-wow-offset="-300" @click="toUrlHandle(swiperItem.btnName)">
            <span>{{ $store.state.lang=='zh'? swiperItem.btnNameLangZh:swiperItem.btnName }}</span>
          </div>
        </div>
      </div>
    </div>

     <div class="banner portfolio" v-if="swiperItem.type === 'portfolio' && lists.length > 0">
      <img width="100%" :src="require('@/assets/images/portfolic-bg.jpg')" alt="" />

      <div class="portfolio-swiper-box center-box">
        <div class="portfolic-title" data-wow-duration="2s" v-if="$store.state.lang=='en'">OUR PORTFOLIO</div>
        <div class="portfolic-title" data-wow-duration="2s" v-if="$store.state.lang=='zh'">投资项目</div>
        <swiper
          style="width: 100%"
          arrow="always"
          :options="portfolioSwiperOption"
        >
          <swiper-slide
            style="width: 100%"
            v-for="(item, index) in Math.ceil(lists.length / 10)"
            :key="index"
          >
            <!-- <div class="mark-box"> -->
            <div class="logos-box">
              <div class="imgbox" 
              v-for="(citem, cindex) in lists.slice(
                    index * 10,
                    10 * item
                  )"
                  :key="'c' + cindex">
                <img
                  :class="
                    ['hover-rubberBand','hover-jello', 'hover-shakeX','hover-pulse']
                    [Math.floor((Math.random()*['hover-rubberBand','hover-jello','hover-shakeX','hover-pulse'].length))]
                  "
                  :src="citem.imgUrlIndex"
                  alt=""
                />
              </div>
              
                
            </div>

            <!-- </div> -->
          </swiper-slide>
        </swiper>
         <!-- 左右箭头 -->
	      <div class="swiper-button-prev" slot="button-prev"></div>
        <div class="swiper-button-next" slot="button-next"></div>
        <div class="portfolic-more " @click="toUrlHandle('MORE PORTFOLIO')">
          <span>MORE PORTFOLIO</span>
        </div>
      </div>
    </div>

    
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

import { getPortfolioContent  } from "@/apis";

import {WOW} from "wowjs"

export default {
  name: "ComplateSwiper",
  components: {
    swiper,
    swiperSlide,
  },
  data() {
    return {
      msg: "Welcome to your vueName",
      startTextScrollID: null,
      portfolioSwiperOption: {
        loop: false,
        // autoplay: {
        //   delay: 10000,
        //   // stopOnLastSlide: false,
        //   disableOnInteraction: false,
        // },
        // 显示分页
        pagination: {
          // el: ".swiper-pagination",
          // clickable: true, //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      swiperOption: {
        loop: true,
        autoplay: {
          // delay: 10000,
          delay: 3000,
          // stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        // 显示分页
        pagination: {
            el: ".swiper-pagination",
            clickable: true //允许分页点击跳转
        },
        // 设置点击箭头
        navigation: {
            // prevButton: ".swiper-button-prev",
            // nextButton: ".swiper-button-next",
        },
      },

      lists: [],
    };
  },
  props: ["swiperItem"],
  methods: {
    animateCSS(element, animation, prefix = "animate__") {
      // We create a Promise and return it
      new Promise((resolve, reject) => {
        const animationName = `${prefix}${animation}`;
        const node = document.querySelector(element);

        node.classList.add(`${prefix}animated`, animationName);

        // When the animation ends, we clean the classes and resolve the Promise
        function handleAnimationEnd(event) {
          event.stopPropagation();
          node.classList.remove(`${prefix}animated`, animationName);
          resolve("Animation ended");
        }

        node.addEventListener("animationend", handleAnimationEnd, {
          once: true,
        });
      });
    },
    handleScroll() {
      // let windowH = document.documentElement.clientHeight;
      // let docSrollTop = document.documentElement.scrollTop||document.body.scrollTop
      // let n =  Math.ceil(docSrollTop / windowH )
      // console.log(docSrollTop);
      // console.log(n);
      // if( 0 < n < 1){
      //   window.scroll(0, windowH)
      // }else if( 1 < n < 2){
      //   window.scroll(0, 2*windowH)
      // }
      
      // let clientH = $(window).height() //视窗大小
      // let sectionTop = $(".sectionBody").offset().top //动态文字模块距离文档头部的距离
      // let sectionH = $(".sectionBody").height()
      // console.log("windowH", windowH);
      // console.log('docSrollTop', docSrollTop);
      // console.log('clientH', clientH);
      // console.dir(this.$refs.aboutusBtn);
    },
    toUrlHandle(name){
      console.log(name)
      if(name === 'ABOUT US'){
        this.$router.push('/about')
      }
      if(name === 'OUR TEAM'){
        this.$router.push('/team')
      }
      if(name === 'MORE PORTFOLIO'){
        this.$router.push('/portfolio')
      }
      
    },
    async getPortfolioContent(){
      const that = this;
      const res = await getPortfolioContent()
      res.data.sort( (a, b) => {
        return b.sort - a.sort
      }).map( (item) => {
        that.lists.push({
          ...item,
          // name: "LEADING AI COMPANY FOR RETAIL DIGITALIZATION",
          // imgUrl: require("@/assets/allImage/logo1.png"),
          key: item.typeid,
        },)
      })
    },
    
  },
  mounted() {
    window.addEventListener('scroll',this.handleScroll, true)
    this.getPortfolioContent()
    // setTimeout(() => {
    //   if(this.swiperItem.options){ 
    //     console.log('--------------->');
    //     console.log(this.swiperItem.options);
    //   }
    // }, 2000);

    this.$nextTick(()=>{
      new WOW().init()
    })
    
    
    
  },
};
</script>

<style scoped lang="scss">
.complate-swiper-warp {
  position: relative;
  width: 100%;
  height: 100%;
  white-space: normal!important;
  img{
    width: 100%!important;
  }
  .banner .mark-box,
  .main-bg-box .mark-box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    // opacity: 0.7;
    color: #fff;
    // background-color: rgba($color: #aaa, $alpha: 0.4);
    transition: all 0.5s;
    .logo {
      // width: 200px;
      // opacity: 0.4;
      // transition: all .5s;
    }
    &:hover {
      // background-color: rgba($color: #333, $alpha: 0.4);
      // opacity: 1;
      // color: #fff;
      // 	.logo{
      // 			opacity: 1;
      // 	}
    }
    .black {
      color: #000;
      &:hover {
        .home-btn-box {
          span {
            // background-color: #2a324d;
            // color: #fff;
            // padding: 14px;
            // cursor: pointer;
            // &:hover{
            // 		background-color: #aaa;
            // 		color: #eee;
            // }
          }
        }
      }
    }
  }
  .home1 {
    position: absolute;
    width: 80%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      // color: #fff;
      transition: color 0.5s;
      font-size: 40px;
      font-weight: bold;
      text-transform: uppercase;
      // height: 72px;
      line-height: 72px;
      text-align: center;
    }
    .desc {
      // color: #fff;
      transition: color 0.5s;
      text-align: center;
      width: 50%;
      line-height: 36px;
      // font-size: 1.2vw;
      font-size: 24px;
      white-space: pre-wrap;
    }
  }
  .banner,
  .main-bg-box {
    max-height: 100%;
    height: auto;
    overflow: hidden;
  }
  .portfolio {
    // width: 80%;
    position: relative;
    .portfolio-swiper-box {
      width: 80%;

      display: inline-block;
      position: absolute;
      // background-color: #fff;
      .logos-box {
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        .imgbox{
          width: 19%;
          margin-right: 1%;
            margin-top: 20px;
            cursor: pointer;
          img {
            width: 90%;
            height: auto;
            // height: 200px;
            
          }
        }
        
      }
      .portfolic-title {
        width: 100%;
        text-align: center;
        color: #fff;
        font-size: 40px;
        font-weight: bold;
        height: 72px;
      }
      .portfolic-more {
        text-align: center;
        font-size: 20px;
        margin-top: 72px;
        span {
          background-color: #fff;
          color: #333;
          padding: 14px;
          cursor: pointer;
          &:hover {
            // background-color: #333;
            // color: #eee;
          }
        }
      }
    }
  }
  .main-box {
    position: absolute;
    min-height: 86%;
    width: 80%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .title {
      // color: #fff;
      transition: color 0.5s;
      font-size: 40px;
      font-weight: bold;
      text-transform: uppercase;
      // height: 72px;
      // line-height: 72px;
    }
    @media screen and (min-width:1200px) {
      .desc {
        font-size: 24px;
      }
    }
    .desc {
      // color: #fff;
      transition: color 0.5s;
      width: 60%;
      font-size: 1.4vw;
      // font-size: 24px;
      line-height: 36px;
      white-space: pre-wrap;
      
    }
    .home-btn-box {
      cursor: pointer;
      span {
        background-color: #fff;
        color: #666;
        padding: 14px;
        cursor: pointer;
        // &:hover{
        // 		// background-color: #333;
        // 		color: #333;
        // }
      }
    }

    // .home-btn-box.light{
    // 	span{
    // 		background-color: #fff;
    // 	}
    // }
  }
  .black {
    .home-btn-box {
      span {
        background-color: #2a324d;
        color: #fff;
      }
    }
  }
  .box-left {
    text-align: left;
    align-items: flex-start;
  }
  .box-right {
    align-items: flex-end;
    text-align: right;
    
  }
  .pulsWidthClass{
    align-items: center;
    text-align: left;
  }
  .swiper-button-prev{
	    // width: 0.36rem;
	    // height: 0.28rem;
      width: 40px;
      height: 40px;
	    background: url("../assets/allImage/left.png") no-repeat;
      
	    background-size: 100% 100%; 
	}
	.swiper-button-next{
	    // width: 0.36rem;
	    // height: 0.28rem;
      width: 40px;
      height: 40px;
	    background: url("../assets/allImage/right.png") no-repeat;
	    background-size: 100% 100%; 
  }
  
  
}
</style>
