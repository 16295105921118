import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/home';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/about'),
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
    component: () => import('@/views/portfolio'),
  },
  {
    path: '/team',
    name: 'Team',
    component: () => import('@/views/team'),
  },
  {
    path: '/member/:id',
    name: 'Member',
    component: () => import('@/views/news/Member.vue'),
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('@/views/news'),
  },
  {
    path: '/news-details/:title',
    name: 'NewsDetails',
    component: () => import('@/views/news/newsDetails.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/contact'),
  },
  // {
  //   path: '/test',
  //   name: 'Test',
  //   component: () => import('@/views/test'),
  // },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
